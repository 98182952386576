<template>
    <div class="accountWrap">
        <div class="accountTop">
            <p class="accountTitle">
                账号信息
            </p>
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </div>
        <div class="accountInfo">
            <p>
                <span style="margin-right: 40px">基本信息</span>
                <span style="cursor: pointer" @click="onEditInfo"><a-icon type="form" /> <span :style="isEdit ? 'color: #1890ff' : ''">{{ isEdit ? '编辑' : '完成' }}</span></span>
            </p>
            <ul class="accountBasicWrap">
                <li style="width: 120px">
                    <img src="@/assets/img/common/user.png" class="accountUserImg">
                    <p class="accountEditImg" @click="isVisible = true">
                        <a-icon type="edit" /> 修改头像
                    </p>
                </li>
                <li>
                    <p class="accountInfoList">
                        <span class="accountInfoTitle">用户名</span>
                        <span v-if="isEdit">{{ form.userName }}</span>
                        <a-input v-else v-model="form.userName" style="width: 400px" />
                    </p>
                    <p class="accountInfoList">
                        <span class="accountInfoTitle">手机</span>
                        <span v-if="isEdit">{{ form.tel }}</span>
                        <a-input v-else v-model="form.tel" style="width: 400px" />
                    </p>
                    <p class="accountInfoList">
                        <span class="accountInfoTitle">邮箱</span>
                        <span v-if="isEdit">{{ form.email }}</span>
                        <a-input v-else v-model="form.email" style="width: 400px" />
                    </p>
                </li>
            </ul>
        </div>
        <div class="accountInfo">
            <p>
                <span style="margin-right: 40px">用户信息</span>
            </p>
            <ul class="accountBasicWrap">
                <li style="width: 120px" />
                <li>
                    <p class="accountInfoList">
                        <span class="accountInfoTitle">用户类型</span><span>企业用户</span>
                    </p>
                    <p class="accountInfoList">
                        <span class="accountInfoTitle">所属机构</span><span>重庆数地科技·名木古树项目</span>
                    </p>
                    <p class="accountInfoList">
                        <span class="accountInfoTitle">角色类型</span><span>子机构管理员</span>
                    </p>
                </li>
            </ul>
        </div>
        <!-- 更换头像弹窗 -->
        <a-modal
            title="修改头像"
            :visible="isVisible"
            ok-text="保存"
            cancel-text="取消"
            :centered="true"
            @cancel="isVisible = false"
            @ok="onSave">
            <a-upload
                list-type="picture"
                :file-list="fileList"
                :show-upload-list="{showRemoveIcon: false}"
                :before-upload="beforeUpload"
                @change="handleChange">
                <a-button type="primary">
                    选择图片
                </a-button>
            </a-upload>
        </a-modal>
    </div>
</template>

<script>
export default {
    name: "Index",
    data() {
        return {
            // 基础信息
            form: {
                userName: 'dsdfds',
                tel: '13233332222',
                email: '122300939@qq.com'
            },
            // 是否编辑基础信息
            isEdit: true,
            // 更换头像弹窗是否可见
            isVisible: false,
            fileList: [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: require('@/assets/img/common/user.png'),
                }
            ],
            isCorrect: false
        }
    },
    methods: {
        gotoHome() {
            this.$router.push('/home')
        },
        // 编辑基础信息
        onEditInfo() {
            this.isEdit = !this.isEdit
            if (this.isEdit) {
                this.$message.success('编辑成功！')
            }
        },
        // 保存图片修改
        onSave() {
            this.isVisible = false
            this.$message.success('修改成功！')
        },
        handleChange(info) {
            if (this.isCorrect) {
                let fileList = [...info.fileList]
                fileList = fileList.slice(-1)
                fileList = fileList.map(file => {
                    if (file.response) {
                        file.url = file.response.url
                    }
                    return file
                })
                this.fileList = fileList
            }
        },
        beforeUpload(file) {
            const _this = this
            let fileName = file.name
            let pos = fileName.lastIndexOf(".")
            let lastName = fileName.substring(pos, fileName.length)
            if (
                lastName.toLowerCase() !== ".png" &&
                lastName.toLowerCase() !== ".jpg"&&
                lastName.toLowerCase() !== ".bmp"
            ) {
                this.isCorrect = false
                this.$message.warn("文件必须为.png .jpg .bmp类型")
                return false
            } else {
                this.isCorrect = true
            }
            return new Promise(function(resolve, reject) {
                if (file.size / 1024 / 1024 > 1) {
                    _this.isCorrect = false
                    _this.$message.warn(`文件大小不能超过1MB`)
                    reject()
                } else {
                    _this.isCorrect = true
                    resolve()
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .accountWrap {
        margin: 30px 80px;
        color: #333;
        .accountTop {
            display: flex;
            justify-content: space-between;
        }
        .accountTitle {
            font-size: 20px;
            font-weight: bold;
        }
        .accountInfo {
            margin-bottom: 20px;
            .accountBasicWrap {
                display: flex;
                .accountUserImg {
                    width: 90px;
                    border-radius: 50%;
                }
                .accountEditImg {
                    margin: 20px 0 0 10px;
                    cursor: pointer;
                }
                .accountInfoList {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    .accountInfoTitle {
                        display: inline-block;
                        width: 100px;
                        margin-right: 30px;
                        text-align: right;
                    }
                }
            }
        }
    }
</style>
